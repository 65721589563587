const pageInfo = {
  // Site Links
  home: {
    navTitle: "Home",
    link: "/",
    meta: {
      title: "PopCrunch",
      description: "Try our product line of delicious, hand-made PopCrunch. We are also a contract manufacturing company for small & large retailers.",
    },
  },
  allCapabilities: {
    navTitle: "Our Capabilities",
    link: "/capabilities",
    meta: {
      title: "Contract Manufacturing for Brittle",
      description: "As a contract manufacturing company, we co-manufacture, co-pack and wholesale hand-made brittle products for both small and large companies & retailers.",
    },
      coManufacturing: {
        navTitle: "Co-Manufacturing",
    //     link: "/capabilities/co-manufacturing",
    //     meta: {
    //       title: "Co-Manufacturing for Brittle",
    //       description: "Our co-manufacturing services provide customers with 1,000 to 5,000 pounds per shift of quality, hand-made brittle and brittle popcorn products.",
    //     },
      },

      coPacking: {
        navTitle: "Co-Packing",
    //     link: "/capabilities/co-packing",
    //     meta: {
    //       title: "Co-Packing Services for Brittle",
    //       description: "Our company provides co-packing services for companies needing their brittle or brittle popcorn products individually packaged and boxed for shipping.",
    //     },
      },

      wholesaling: {
        navTitle: "Wholesaling",
    //     link: "/capabilities/wholesaling",
    //     meta: {
    //       title: "Wholesale Brittle Products",
    //       description: "Our production facility can produce 1,000 - 5,000 pounds of brittle and brittle popcorn per shift and can provide wholesale opportunities for your company.",
    //     },
      },
  },
  about: {
    navTitle: "About Us",
    link: "/about",
    meta: {
      title: "About Our Company & Our History",
      description: "Learn more about our company’s history, how our brittle products are small-batch, hand-made, and made only with the finest and freshest ingredients.",
    },
  },
  contact: {
    navTitle: "Contact",
    link: "/contact",
    meta: {
      title: "Contact Us For Your Brittle Needs",
      description: "Reach Big Dipper Food Company at 1-800-BRITTLE, by email, or by using our contact form for any brittle product questions you may have. Call today!",
    },
  },
  store: {
    navTitle: "Buy Now",
    link: "https://bigdipperfoodcompany.square.site/shop/pop-crunch/5",
  },
  termsOfService: {
    navTitle: "Terms of Service",
    link: "/policies/terms-service",
    meta: {
      title: "Terms of Service",
      description: "Read through Big Dipper Food Company’s contract manufacturing terms and conditions to learn more about interacting with our websites.",
    },
  },
  privacyPolicy: {
    navTitle: "Privacy Policy",
    link: "/policies/privacy-policy",
    meta: {
      title: "Privacy Policy",
      description: "Big Dipper Food Company’s contract manufacturing privacy policy explains our commitment to protecting your online privacy what information we may collect.",
    },
  },
  // Contact Info
  phone: {
    number: "1-800-BRITTLE",
    link: "tel:(800) 274-8853",
  },
  email: {
    address: "Info@BigDipperFood.com",
    link: "mailto:info@bigdipperfood.com",
  },
  // Social Links
  facebook: {
    link: "https://www.facebook.com/bigdipperfood/",
  },
  instagram: {
    link: "https://www.instagram.com/bigdipperfood/",
  },
};

export default pageInfo;
