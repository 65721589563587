import React from "react";

import Header from "../header/Header";
import ImrozFooter from "../footer/Footer";
import BackToTop from "../BackToTop";

const Layout = (props) => {
  return (
    <>
      <Header />
      {props.children}
      {/* <ImrozFooter /> */}
      {/* <BackToTop /> */}
    </>
  );
};

export default Layout;
