import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { slideSlick } from "./script.js";


import pageInfo from "../../config/scripts/pageInfo.js";

import style, { largeMobile } from "./HomeHeroSlider.module.scss";

import useMediaQuery from "../../../hooks/useMediaQuery.jsx";

// import bgImageMobile from "../../../../public/assets/images/hero/big-dipper-food-company-brittle-contract-food-manufacturing-hero-1.webp";


const SlideList = [
  // {
  //   id: 1,
  //   title: "2023 Most Innovative New Product",
  //   titleClass: style['title-column'],
  //   description: "Don't Just Take Our Word for It.",
  //   buttonText: "Read Press Release",
  //   buttonLink: "https://www.prnewswire.com/news-releases/sweets--snacks-expo-announces-2023-most-innovative-new-product-award-winners-301831469.html",
  //   linkType: "external",
  //   bgImage: "bg_image--6",
  //   textPosition: "text-right",
  //   wrapperClass: style.wrapper,
  //   column: 'col-lg-6',
  //   category: "",
  // },
  {
    id: 2,
    title: "PopCrunch",
    titleClass: style.title,
    description: "Our Award-Winning Popcorn, with a Crunch!",
    buttonText: "Buy Now",
    buttonLink: "https://bigdipperfoodcompany.square.site/shop/pop-crunch/5",
    linkType: "external",
    bgImageDesktop: "bg_image--1",
    textPosition: "text-center",
    column: 'col-lg-12',
    category: "",
  },
  // {
  //   id: 3,
  //   title: "We Use Only The Finest Natural Ingredients.",
  //   titleClass: style.title,
  //   description: "",
  //   textPosition: "text-center",
  //   bgImage: "bg_image--2",
  //   category: "",
  //   buttonText: "Learn More",
  //   buttonLink: pageInfo.about.link,
  //   linkType: "internal",
  //   column: 'col-lg-12'
  // },
  // {
  //   id: 4,
  //   textPosition: "text-center",
  //   bgImage: "bg_image--3",
  //   category: "",
  //   title: "Buy Our Delicious Natural Brittle Products.",
  //   titleClass: style.title,
  //   description:
  //     "We make our own all-natural retail products that you'll love.",
  //   buttonText: "View Products",
  //   buttonLink: pageInfo.store.link,
  //   linkType: "external",
  //   column: 'col-lg-12'
  // },
  // {
  //   id: 5,
  //   textPosition: "text-center",
  //   bgImage: "bg_image--4",
  //   category: "",
  //   title: "Co-Manufacturing & Co-Packing Solutions.",
  //   titleClass: style.title,
  //   description:
  //     "For Brittle & Brittle Popcorn production.",
  //     buttonText: "View Capabilities",
  //     buttonLink: pageInfo.allCapabilities.link,
  //     linkType: "internal",
  //     column: 'col-lg-12'
  // },
];

const HomeHeroSlider = () => {
  const isMobile = useMediaQuery("(max-width: 575px)");
  // const bgImageMobile = "bg_image--1";

  console.log(isMobile);
  console.log(largeMobile);
  return (
    <section id="hero">
      <div className="slider-wrapper">
        <div className="slider-activation">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {SlideList.map((value) => (
              <div
                className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${isMobile ? "bg_image--2" : value.bgImageDesktop}`}
                key={value.id}
                data-black-overlay="3"
              >
                <div className="container">
                  <div className={`row ${value.wrapperClass}`}>
                    <div className={value.column}>
                      <div className={`inner ${value.textPosition}`}>
                        {/* {value.category ? <span>{value.category}</span> : ""} */}
                        <h2 className={value.titleClass}>{value.title}</h2>
                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ""
                        )}
                        <div className="slide-btn">
                          {value.linkType === "internal" ? (
                            <Link
                              className="rn-button-style--2 btn-solid-dark-bg"
                              to={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </Link>
                          ) : (
                            <a
                              className="rn-button-style--2 btn-solid-dark-bg"
                              href={`${value.buttonLink}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {value.buttonText}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomeHeroSlider;
