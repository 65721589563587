import React from "react";
import Helmet from "../../components/common/Helmet";
import Hero from "../../components/hero/Hero";
import HomeIntro from "./components/HomeIntro";
import CapabilitiesBlocks from "../../components/CapabilitesBlocks/CapabilitiesBlocks";
import CallAction from "../../components/callaction/CallToAction";

import pageInfo from "../config/scripts/pageInfo";
import MainButtonWhite from "../../components/ui/Buttons/MainButtonWhite";

const Home = (props) => {
  const cta = {
    mainText: "Need More Information?",
    smallText: "Contact Us For Any of Your Brittle, Co-Manufacturing, Co-Packing or Wholesale Needs!",
    buttonSrc: pageInfo.contact.link,
    buttonCopy: pageInfo.contact.navTitle + " Us",
  };
  return (
    <>
      <Helmet metaTitle={pageInfo.home.meta.title} metaDesc={pageInfo.home.meta.description} />
      <Hero />
      {/* <section id="content">
      <HomeIntro />
        <div className="rn-about-area ptb--100 bg_color--2" style={{backgroundImage: "url(/assets/images/pattern-6.png)"}}>
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/home/section/big-dipper-food-company-brittle-contract-food-manufacturing-natural-ingredients-brittle.webp"
                    alt="Peanut brittle stacked in layers on a small, white dish with a red and black plaid hand-towel in the background."
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2
                      className="title"
                      style={{
                        color: "white",
                        lineHeight: "1.1",
                        paddingBottom: "20px",
                      }}
                    >
                      Only Natural Ingredients
                    </h2>
                    <p className="white-text">
                      With the simple amount of natural ingredients we use to create our brittle products, we're sure they'll "WOW" you with their cosmic flavor! Quality has been a pillar of our company since our inception, and you'll taste this intention in every bite of our small-batch, hand-stirred products.
                    </p>
                  </div>
                  <MainButtonWhite
                    position="left"
                    link={pageInfo.about.link}
                    target="_self"
                    rel=""
                    mt={`50`}
                    mb={`0`}
                  >
                  Learn More
                </MainButtonWhite>
                </div>
              </div>
            </div>
          </div>
        </div>
      <CapabilitiesBlocks />
      </section>  
      <CallAction
        mainText={cta.mainText}
        smallText={cta.smallText}
        buttonSrc={cta.buttonSrc}
        buttonCta={cta.buttonCopy}
      /> */}
    </>
  );
};
export default Home;
